import type { MergeHead, Script, UseHeadInput } from "@unhead/vue";

const isDev = import.meta.env.MODE === "development";

export type MetaRoutes =
  | "home"
  | "login"
  | "panel"
  | "universal-search-check"
  | "advanced-search-check";

export const script: Script[] = !isDev
  ? [
      {
        type: "text/javascript",
        async: true,
        src: "//code.jivosite.com/script/widget/iY5SxycXsA",
      },
      {
        type: "text/javascript",
        innerHTML: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      ym(46578816, "init", {
           clickmap:true,
           trackLinks:true,
           accurateTrackBounce:true,
           webvisor:true,
           ecommerce:"dataLayer"
      });`,
      },
    ]
  : [];

export const meta = new Map<MetaRoutes, UseHeadInput<MergeHead>>();
meta.set("home", {
  title: "ИРБИС.ПРАКТИКА. Поиск судов. Подготовка к судебному заседанию.",
  meta: [
    {
      name: "description",
      content:
        "Найти судебное дело по фамилии и решение суда в 1 клик. Программа для юристов: анализ судов общей юрисдикции. База всех судебных дел СОЮ России. Проверьте ход дела за секунды.",
    },
    {
      name: "keywords",
      content:
        "иск судов, судебные дела по фамилии, найти судебный приказ, поиск судебных дел, по для юриста, база судов, сайты поиска судов, банк судебных решений, подготовка к судебному заседанию",
    },
  ],
  script,
});
meta.set("login", {
  title:
    "ИРБИС.ПРАКТИКА. Вход в личный кабинет. Сервис анализа судов для юриста.",
  meta: [
    {
      name: "description",
      content:
        "Легкий поиск судебных дел и анализ практики судов России. Статистика по решениям судьи для подготовки к судебному заседанию. Отслеживайте движение дела в суде.",
    },
    {
      name: "keywords",
      content:
        "аналитика судов, решение суда по делу, поиск по судам, поиск по решениям судов",
    },
  ],
  script,
});
meta.set("panel", {
  title: "ИРБИС.ПРАКТИКА. Поиск судов. Подготовка к судебному заседанию.",
  script,
});
meta.set("universal-search-check", {
  title: "ИРБИС.ПРАКТИКА. Поиск судов. Подготовка к судебному заседанию.",
  script,
});
meta.set("advanced-search-check", {
  title: "ИРБИС.ПРАКТИКА. Поиск судов. Подготовка к судебному заседанию.",
  script,
});
