<script setup lang="ts">
import type { INavLink } from "@/shared/utils/types";
import { useRouter } from "vue-router";
import { IrbisLink, LinkSkin } from "@ainform/irbis-ui-storybook";
import { useAuthStore } from "@/store/useAuthStore/useAuthStore";

interface IProps {
  listClass?: string;
  listItemClass?: string;
}
const props = defineProps<IProps>();
const styles = useCssModule();
const listClasses = computed(() => [styles.list, props.listClass]);

const router = useRouter();
const { isAdmin } = storeToRefs(useAuthStore());

const navItems = computed<INavLink[]>(() => {
  const base: INavLink[] = [
    { id: 2, to: { name: "universal-search-check" }, title: "Сервис" },
    { id: 3, to: { name: "home", hash: "#tariff" }, title: "Тарифы" },
    { id: 4, to: { name: "home", hash: "#info" }, title: "Демо" },
    { id: 5, to: { name: "home", hash: "#contact" }, title: "Контакты" },
  ];
  if (router.currentRoute.value.name !== "home") {
    base.unshift({
      id: 1,
      to: { name: "home" },
      title: "Главная",
    });
  }
  if (isAdmin.value) {
    base.push({
      id: 6,
      to: { name: "panel" },
      title: "Администрирование",
    });
  }
  return base;
});
</script>

<template>
  <nav>
    <ul :class="[$style.list, listClasses]">
      <li v-for="{ id, to, title } in navItems" :key="id" :class="$style.item">
        <IrbisLink
          :to="to"
          :class="[$style.navLink, listItemClass]"
          :skin="LinkSkin.EMPTY"
        >
          {{ title }}</IrbisLink
        >
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" module>
.list {
  display: flex;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
}
.item {
  padding: 0 20px;
  margin: 0;
}
.navLink {
  @include link();
  font-size: 15px;
  border-bottom: 2px solid transparent;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    border-bottom: 2px solid $main;
  }
}
</style>
