<script setup lang="ts">
interface IProps {
  open: boolean;
}

const props = defineProps<IProps>();
defineEmits(["toggle"]);

const id = "navHamburger";
const styles = useCssModule();

const hamburgerClasses = computed(() => [
  styles.hamburger,
  props.open && styles.hamburgerOpen,
]);
</script>

<template>
  <div>
    <label :for="id" :class="hamburgerClasses" />
    <input
      :id="id"
      :checked="open"
      type="checkbox"
      hidden
      @click="$emit('toggle')"
    />
  </div>
</template>

<style lang="scss" module>
.hamburger {
  margin: 8px 20px 0;
  cursor: pointer;
  color: $white;
  height: 1.325em;
  width: 30px;
  padding: 0.6em 0;
  background-color: $main;
  background-clip: content-box;
  position: relative;
  transition: background-color 300ms ease;

  &::before,
  &::after {
    content: "";
    background-color: $main;
    height: 0.12em;
    left: 0;
    position: absolute;
    width: 100%;
    transition:
      300ms ease,
      top 300ms ease;
  }
  &::before {
    top: 0;
  }
  &::after {
    top: 1.25em;
  }
}

.hamburgerOpen {
  background-color: transparent;
  &::before {
    top: 0.6em;
    transform: rotate(45deg) scale(1.2);
  }
  &::after {
    top: 0.6em;
    transform: rotate(-45deg) scale(1.2);
  }
}
</style>
