<script setup lang="ts">
import { DropDown } from "@ainform/irbis-ui-storybook";
import AppHeaderHamburger from "@/components/layout/AppHeader/AppHeaderMobileMenu/AppHeaderHamburger.vue";
import AppHeaderNav from "@/components/layout/AppHeader/AppHeaderNav.vue";
import AppHeaderUserMobile from "@/components/layout/AppHeader/AppHeaderMobileMenu/AppHeaderUserMobile.vue";
import { useToggle } from "@vueuse/core";

const [open, setOpen] = useToggle();

const handleToggle = () => setOpen(!open.value);
const handleClose = () => setOpen(false);
</script>

<template>
  <DropDown
    :visible="open"
    :offset="16"
    :content-class="$style.dropdownContent"
    placement="bottom-end"
    @toggle="handleToggle"
    @close="handleClose"
  >
    <template #trigger>
      <AppHeaderHamburger :open="open" @toggle="handleToggle" />
    </template>
    <template #content>
      <AppHeaderNav
        :list-class="$style.list"
        :list-item-class="$style.listItem"
      />
      <AppHeaderUserMobile />
    </template>
  </DropDown>
</template>

<style lang="scss" module>
.list {
  display: flex;
  flex-direction: column !important;
  margin: 0;
  padding: 0 20px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.listItem {
  display: flex;
  color: #404040;
  font-size: 15px;
  text-decoration: none;
  width: 100%;
  padding: 10px 40px;
}
</style>
