<script setup lang="ts">
import { useAuthStore } from "@/store/useAuthStore/useAuthStore";
import AppHeaderButtons from "@/components/layout/AppHeader/AppHeaderUser/AppHeaderButtons.vue";
import { ChevronIcon } from "@ainform/irbis-ui-storybook";
import userPlaceholder from "@/shared/assets/images/user-placeholder.png";

const authStore = useAuthStore();
const { logout } = authStore;
const { credentials } = storeToRefs(authStore);

const menuVisible = ref(false);
function toggleMenuVisible() {
  menuVisible.value = !menuVisible.value;
}
</script>

<template>
  <div v-if="credentials.authenticated" :class="$style.loginPanel">
    <div :class="$style.loginPanelControls" @click="toggleMenuVisible">
      <img :class="$style.controlsImage" :src="userPlaceholder" alt="" />
      <span :class="$style.controlsMiddle">Личный кабинет</span>
      <ChevronIcon
        :active="menuVisible"
        :rotation-angle="90"
        :color="'#8c8af9'"
      />
    </div>
    <transition>
      <ul v-if="menuVisible" :class="$style.loginMenu">
        <li :class="$style.loginMenuItem" @click="logout">Выйти</li>
      </ul>
    </transition>
  </div>
  <div v-else><AppHeaderButtons /></div>
</template>

<style module lang="scss">
.loginPanel {
  @include flex(center, center);
  flex-direction: column;
  background: #ffffff;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.loginPanelControls {
  @include flex(center, center);
  width: 100%;
  padding: 10px 0;
  &:hover {
    cursor: pointer;
  }
}
.controlsImage {
  width: 35px;
  height: 35px;
}
.controlsMiddle {
  margin: 0 13px;
}
.loginMenu {
  margin: 0 0 16px 0;
  padding: 0;
  background-color: #f4f4ff;
  width: 100%;
  border-block: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.loginMenuItem {
  list-style: none;
  padding: 10px 0;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}
</style>
